<template>
  <div class="homepage-wrapper">
    <section class="banner">
      <div class="container">
        <div class="row">
          <div
            class="col-12 col-md-10 col-lg-6"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <h3>
              Bepaal zelf waar je werkt
              <span class="typed-text">{{ typeValue }}</span>
              <span class="cursor" :class="{ typing: typeStatus }">&nbsp;</span>
            </h3>
            <p
              style="font-family: Arial, Helvetica, sans-serif;font-weight:bold;color:#64b5f6;font-size:20px"
            >
              Vind het perfecte locatie in jouw buurt.
            </p>
            <div class="input-search">
              <v-autocomplete
                hide-details
                v-model="selectedCities"
                class="p-0 m-0"
                rounded
                autofocus
                small-chips
                placeholder="Zoek een werkplek bij jou in de buurt?"
                multiple
                deletable-chips
                :items="cities"
              />
              <button type="button" @click="moveToFlexplekken">Zoeken</button>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="why alternate-section">
      <div class="main-section-content">
        <v-container>
          <v-row>
            <v-col
              cols="12"
              md="6"
              order="1"
              order-md="0"
              data-aos="fade-right"
            >
              <h2>Over Kantoorflex</h2>
              <p>
                Werk je vanuit huis? Of op het kantoor van je klant? En heb je
                geen eigen of een voltijds kantoorruimte in Nederland nodig? Bij
                Kantoorflex kun je kiezen tussen onbeperkt gebruik van
                flexwerken in één van onze dynamische flexwerkplek locatie. Of
                kies voor een aantal dagen per maand een meer rustige
                flexwerkplek te huren in een afgesloten kantoor. De keuze is
                geheel aan jou. In beide gevallen staat ons serviceteam van
                Kantoorflex voor je klaar.
              </p>
              <v-btn
                color="bg-white mt-5"
                elevation="9"
                large
                raised
                rounded
                @click="goToAbout()"
              >
                Meer over Kantoorflex
              </v-btn>
            </v-col>
            <v-col cols="12" md="6" order="0" order-md="1">
              <v-img
                class="align-end why-section-image"
                height="300px"
                src="@/assets/homepage/about.jpg"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="diagonal diagonal-bottom-right"></div>
    </section>

    <section class="places">
      <h2 class="text-center mb-5" style="font-weight:bold">Populaire steden</h2>

      <v-container>
        <v-row>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/stad/Rotterdam.png"
                >
                  <v-card-title>Rotterdam</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="#088dd3"
                        class="text-decoration-none"
                        :to="`/Flexplekken?cities=Rotterdam`"
                        >Bekijken</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/stad/Denhaag.png"
                >
                  <v-card-title>Den Haag </v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="#088dd3"
                        class="text-decoration-none"
                        :to="`/Flexplekken?cities=Den Haag`"
                        >Bekijken</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/stad/Almere.png"
                >
                  <v-card-title>Almere</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="#088dd3"
                        class="text-decoration-none"
                        :to="`/Flexplekken?cities=Almere`"
                        >Bekijken</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/stad/Amsterdam.png"
                >
                  <v-card-title>Amsterdam</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="#088dd3"
                        class="text-decoration-none"
                        :to="`/Flexplekken?cities=Amsterdam`"
                        >Bekijken</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/stad/Arnhem.png"
                >
                  <v-card-title>Arnhem</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="#088dd3"
                        class="text-decoration-none"
                        :to="`/Flexplekken?cities=Arnhem`"
                        >Bekijken</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
          <v-col cols="12" sm="6" md="4" data-aos="zoom-in-up">
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 24 : 2"
                :class="{ 'on-hover': hover }"
                class="mx-auto"
                max-width="400"
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  src="@/assets/stad/Utrecht.png"
                >
                  <v-card-title>Utrecht</v-card-title>
                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="blue">
                      <v-btn
                        color="#088dd3"
                        class="text-decoration-none"
                        :to="`/Flexplekken?cities=Utrecht`"
                        >Bekijken</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <!-- Howe to begin section -->
    <section class="workspaces alternate-section">
      <div class="diagonal diagonal-top-left"></div>
      <div class="main-section-content">
        <h2 class="text-center mb-5 text-black" style="font-weight:bold">
          Reserveer vandaag nog bij Kantoorflex
        </h2>
        <p class="text-center mb-5 text-black">Het reserveren van een flexibele kantoor-, winkel- of bedrijfsruimte is nooit zo gemakkelijk geweest.</p>
          <p class="text-center mb-5 text-black"> Ook voor een feestje kun je een ruimte reserveren bij een van onze partners.
        </p>
        <v-container>
          <v-row>
            <v-col>
              <v-card max-width="344">
                <v-img
                  src="@/assets/homepage/orienteren.jpg"
                  height="200px"
                ></v-img>

                <v-card-title>
                  Ontdekken
                </v-card-title>

                <v-card-subtitle>
                  Vind de perfecte kantoor-, winkel- of bedrijfsruimte of feest locatie die het beste bij jou past.
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col>
              <v-card max-width="344">
                <v-img
                  src="@/assets/homepage/reserveren.jpg"
                  height="200px"
                ></v-img>

                <v-card-title>
                  Reserveren
                </v-card-title>

                <v-card-subtitle>
                  Maak een account aan om een werk plek te Reserveren. Dit kan eenvoudig op onze website.
                </v-card-subtitle>
              </v-card>
            </v-col>
            <v-col>
              <v-card max-width="344">
                <v-img
                  src="@/assets/homepage/eerstedag.png"
                  height="200px"
                ></v-img>

                <v-card-title>
                  Op locatie
                </v-card-title>

                <v-card-subtitle>
                  Maak het jezelf gemakkelijk! We zorgen dat je kantoor volledig
                  operationeel is, zodat je meteen aan het werk kunt.
                </v-card-subtitle>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </section>

    <section class="booking text-center mx-auto">
      <h2 class="mb-5" style="font-weight:bold">Meld je pand vandaag nog aan</h2>
      <p class="mb-5">
        Voeg je flexlocatie toe in je eigen beschermde omgeving. Optimaliseer,
        beheer en ontvang gemakkelijk reserveringen voor je locatie.
      </p>
      <div>
        <router-link to="Inloggen" class="text-decoration-none"
          ><v-btn style="background-color:#088dd3;color:white;font-weight:bold"> Pand toevoegen </v-btn></router-link
        >
      </div>
    </section>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
import cities from "@/assets/cities.js";
export default {
  data: () => {
    return {
      typeValue: "",
      typeStatus: false,
      typeArray: ["Amsterdam", "Rotterdam", "Den Haag", "Gouda", "Utrecht"],
      typingSpeed: 200,
      erasingSpeed: 100,
      newTextDelay: 2000,
      typeArrayIndex: 0,
      charIndex: 0,
      places: [],
      cities: cities,
      selectedCities: [],
    };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    BLink,
  },
  async mounted() {
    await this.getAllPlaces();
  },
  methods: {
    moveToFlexplekken() {
      if (this.selectedCities.length === 0) return;
      this.$router.push("/Flexplekken?cities=" + this.selectedCities.join(","));
    },

    goToAbout() {
      this.$router.push("/Overons");
    },
    typeText() {
      if (this.charIndex < this.typeArray[this.typeArrayIndex].length) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue += this.typeArray[this.typeArrayIndex].charAt(
          this.charIndex
        );
        this.charIndex += 1;
        setTimeout(this.typeText, this.typingSpeed);
      } else {
        this.typeStatus = false;
        setTimeout(this.eraseText, this.newTextDelay);
      }
    },
    eraseText() {
      if (this.charIndex > 0) {
        if (!this.typeStatus) this.typeStatus = true;
        this.typeValue = this.typeArray[this.typeArrayIndex].substring(
          0,
          this.charIndex - 1
        );
        this.charIndex -= 1;
        setTimeout(this.eraseText, this.erasingSpeed);
      } else {
        this.typeStatus = false;
        this.typeArrayIndex += 1;
        if (this.typeArrayIndex >= this.typeArray.length)
          this.typeArrayIndex = 0;
        setTimeout(this.typeText, this.typingSpeed + 1000);
      }
    },
  },
  created() {
    setTimeout(this.typeText, this.newTextDelay + 200);
  },
};
</script>

<style>
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: inherit !important;
}
</style>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.homepage-wrapper {
  --primary-color: #f7f7f7;
  --alternate-color: #000;
  font-family: "Poppins", sans-serif;
}
section.alternate-section .main-section-content {
  background-color: var(--primary-color);
  color: var(--alternate-color);
}

section.why .main-section-content {
  padding: 70px 0px 35px;
}
section.workspaces {
  margin-top: 50px;
}
section.workspaces .main-section-content {
  padding: 35px 0px 70px;
}

.why-section-image {
  border-radius: 10px 50px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
}
.diagonal {
  height: 100px;
}
.diagonal-bottom-right {
  background-image: linear-gradient(
    to bottom left,
    var(--primary-color) 49%,
    transparent 50%
  );
}
.diagonal-top-left {
  background-image: linear-gradient(
    to top right,
    var(--primary-color) 49%,
    transparent 50%
  );
}
.places {
  padding-top: 60px;
}

.booking {
  padding: 50px 20px;
  width: 100%;
  max-width: 800px;
}

.partners {
  padding: 50px 0px;
  background-color: #e7e7e5;
  text-align: center;
}
.wrapper {
  display: flex;
}

.input-search {
  margin-top: 20px;
  display: flex;
  align-items: center;
  padding: 5px 20px 5px 6px;
  background-color: #ffffff;
  border: none;
  border-radius: 70px;
  outline: 0;
  color: #000000;
  box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2) !important;
}
.input-search button {
  background-color: #088dd3;
  color: #ffffff;
  border: none;
  height: 45px;
  padding: 0 25px;
  border-radius: 70px;
  transition: 0.5s;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
}


input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

header {
  position: absolute;
  z-index: 10;
  width: 100%;
}
.navbar {
  padding: 20px 0px;
}

.navbar-brand {
  font-weight: 800;
  text-transform: uppercase;
}

.banner {
  background-image: url("~@/assets/homepage/slider.png");
  background-size: cover;
  width: 100%;
  padding: 100px 0px 60px;
}

.banner h3 {
  margin: 0;
  padding: 0;
  font-size: 1.8rem;
  font-weight: 1000;
}

.banner p {
  margin: 1em 0 0;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.5em;
}

@media (max-width: 768px) {
  .banner {
    background-image: url("~@/assets/homepage/slider.png");
    width: 100%;
    padding: 100px 0px 250px;
  }
}

h3 span.typed-text {
  color: orange;
}

span.cursor {
  display: inline-block;
  margin-left: 3px;
  width: 4px;
  background-color: black;
  animation: cursorBlink 1s infinite;
}
span.cursor.typing {
  animation: none;
}

@keyframes cursorBlink {
  49% {
    background-color: black;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
}
.link {
  color: rgba(79, 89, 98);
  text-decoration: none;
  transition: color 300ms ease-in-out;
}
.link:hover {
  color: rgba(79, 89, 98, 0.5);
}
.card-image-link {
  overflow: hidden;
  cursor: pointer;
}
.card-image {
  transition: 300ms;
}
.card-image:hover {
  transform: scale(1.2);
}
</style>
